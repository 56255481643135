import { log } from "./clientlog";
import { ConsentType, MemberDTO, StoreboxIframeRequestDTO, TicketReplyRequestDto, SupportTicketDto } from "../apiclient/MemberAPI";
import { Api as MemberApiSwagger } from "../apiclient/MemberAPI";
import { Api as VippsApiSwagger } from "../apiclient/VippsAPI";
import { Constants } from "../constants/constants";
import { AuthRequest, ILoyallApiService } from "../types/interface";
import { AuthenticationTypes, ConsoleType, RedeemType } from "../types/enum";

interface SSO {
	Type: string | null;
	Token: string | null;
}
export function SSOCallbackHandler(): SSO {
	let loc = window.location.search;
	let hash = window.location.hash;
	// in case that search located in hash
	if (!loc || (loc.length === 0 && hash)) {
		if (!hash.startsWith("?code=") && hash.includes("?code=")) hash = hash.substring(1).replace("?code=", "&code=");
		const tempURL = new URL(window.location.origin + window.location.pathname + hash);
		loc = tempURL.search;
	}
	const ssoCode = new URLSearchParams(loc).get(Constants.SSO_PARAM);
	const ssoType = new URLSearchParams(loc).get(Constants.SSO_TYPE);
	log(`sso code:${ssoCode} , sso type:${ssoType} , loc = ${loc}`, ConsoleType.Trace);
	return {
		Token: ssoCode,
		Type: ssoType,
	};
}

export const LoyallApiService = (memberAPI: MemberApiSwagger<string>, vippsAPI: VippsApiSwagger<string>): ILoyallApiService => {
	const withToken = (token: string | null, f: any) => {
		memberAPI.setSecurityData(token);
		return f();
	};
	return {
		CreateVerificationCode: (programId: number, phoneNumber: string) =>
			memberAPI.api
				.memberAuthCreateMemberVerificationCode({
					programId: programId,
					phoneNumber: phoneNumber,
				})
				.then((x) => x.data),
		CreateTokenByMRC: async (MRC: string) => {
			return memberAPI.api.memberAuthCreateTokenFromMrcId(MRC).then((x) => x.data);
		},
		Authenticate: (request: AuthRequest) => {
			switch (request.Type) {
				case AuthenticationTypes.Mobile:
					if (request.Data) return memberAPI.api.memberAuthMemberLogin(request.Data).then((x) => x.data);
					return null;
				case AuthenticationTypes.Vipps:
					if (request.VippsCode) {
						const ret = vippsAPI.api
							.vippsLogin({
								code: request.VippsCode,
								programId: request.ProgramId,
							})
							.then((x) => x.data);
						return ret;
					}
					return null;
				case AuthenticationTypes.JWT:
					return null;
			}
		},
		GetMemberInfo: (token: string | null) => withToken(token, () => memberAPI.api.memberGetMemberInfo().then((x) => x.data)),
		GetBonusInfo: (token: string | null) => withToken(token, () => memberAPI.api.bonusGetMemberBonus().then((x) => x.data)),
		GetVoucherInfo: (token: string | null) => withToken(token, () => memberAPI.api.voucherGetActive().then((x) => x.data)),
		UpdateMemberInfo: async (token: string | null, params: MemberDTO, programId: number) => {
			memberAPI.setSecurityData(token);
			var m = await memberAPI.api.memberGetMemberInfo();
			const programTag = sessionStorage.getItem(Constants.ProgramTagSession);
			if (programTag) params.programTag = programTag;
			const newData: MemberDTO = { ...m.data, ...params };
			return memberAPI.api.memberUpdateMemberProfile({ member: newData, programId: programId }).then((x) => x.data);
		},
		GetTermsOfService: (programId: number, language: string) => memberAPI.api.termsOfServiceGetById(programId, language).then((x) => x.data),
		GetReceiptInfo: (token: string | null) => {
			memberAPI.setSecurityData(token);
			return withToken(token, () => memberAPI.api.receiptGetTransactions().then((x) => x.data));
		},
		GetCardInfo: (token: string | null) => {
			memberAPI.setSecurityData(token);
			return withToken(token, () => memberAPI.api.cardGetMemberCards().then((x) => x.data));
		},
		SetCardByFrame: async (token: string | null, params: StoreboxIframeRequestDTO) => {
			memberAPI.setSecurityData(token);
			return memberAPI.api.cardGetCardFrame(params).then((x) => x.data);
		},
		SetCardByBankAxept: async (token: string | null, accountNumber?: string) => {
			let error = "error! please try again, later.";
			try {
				memberAPI.setSecurityData(token);
				await memberAPI.api.cardRegisterBankaxept({ accountNumber: accountNumber });
				error = "";
			} catch (e: any) {
				error = await e.text();
				error = error.split(":")[1]?.substring(1) ?? "error!";
			}
			return error;
		},
		GetVippsRedirect: async (returnURL?: string) => {
			const temp = await vippsAPI.api.vippsGetVippsProxyUrl({
				returnURL: returnURL,
			});
			return temp.data.redirectURL ?? "";
		},
		ApplyBonusOverTime: async (token: string | null, merchantId?: number, amount?: number, redeemedBy?: string, redeemedByEmail?: string, reason?: string) => {
			memberAPI.setSecurityData(token);
			return await memberAPI.api
				.bonusApply({
					amount: amount,
					merchantId: merchantId,
					reason: reason,
					redeemedBy: redeemedBy,
					redeemedByEmail: redeemedByEmail,
					externalReferenceNumber: "",
					terminalId: "",
				})
				.then((x) => x.data);
		},
		ApplyVoucherOverTime: async (token: string | null, voucherRef: string, redeemedBy: string, reason: RedeemType) => {
			memberAPI.setSecurityData(token);
			return await memberAPI.api.voucherApply(voucherRef, { redeemedBy: redeemedBy, reason: reason }).then((x) => x.data);
		},
		GetMemberConsent: (token: string | null) => withToken(token, () => memberAPI.api.consentGetMemberConsent().then((x) => x.data)),
		UpdateMemberConsent: async (token: string | null, consentType: ConsentType, create: boolean) => {
			memberAPI.setSecurityData(token);
			switch (create) {
				case true:
					await memberAPI.api.consentCreateMemberConsent({ consentType: consentType });
					break;
				case false:
					await memberAPI.api.consentDeleteMemberConsent({ consentType: consentType });
					break;
			}
		},
		DeleteMemberCard: async (token: string | null, cardId: string) => {
			let error = "error! please try again, later.";
			try {
				memberAPI.setSecurityData(token);
				await memberAPI.api.cardRemoveCard(cardId).then((x) => x.data);
				error = "";
			} catch (e: any) {
				error = await e.text();
				error = error.split(":")[1]?.substring(1) ?? "error!";
			}
			return error;
		},
		GetTicketsInfo: (token: string | null) => withToken(token, () => memberAPI.api.ticketGetTickets().then((x) => x.data)),
		CreateTicket: async (token: string | null, request: SupportTicketDto) => {
			let error = "error! please try again, later.";
			memberAPI.setSecurityData(token);
			try {
				memberAPI.setSecurityData(token);
				await memberAPI.api.ticketCreateTicket(request).then((x) => x.data);
				error = "";
			} catch (e: any) {
				error = JSON.parse(JSON.stringify(await e.json())).title;
			}
			return error;
		},
		ReplyTicket: async (token: string | null, reply: TicketReplyRequestDto) => {
			let error = "error! please try again, later.";
			memberAPI.setSecurityData(token);
			try {
				memberAPI.setSecurityData(token);
				await memberAPI.api.ticketReplyTicket(reply).then((x) => x.data);
				error = "";
			} catch (e: any) {
				error = JSON.parse(JSON.stringify(await e.json())).title;
			}
			return error;
		},
		GetFaqInfo: (token: string | null, language: string) => withToken(token, () => memberAPI.api.faqGetFaq({ language: language }).then((x) => x.data)),
	};
};
